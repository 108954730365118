import * as React from "react"

import "../styles/normalize.css"
import "../styles/main.css"

import Chat from "../components/chat/Chat"
import forumBgrSrc from "../images/forumBgr.png"

export default function IndexPage() {
  return (
    <main style={{ paddingBottom:100 }}>
      <div style={{ backgroundImage:`url(${forumBgrSrc})`, backgroundPositionX:`center`, backgroundRepeat:`no-repeat`, height:600 }} />
      <div style={{ width:`1140px`, maxWidth:`100vw`, height:`500px`, margin:`0 auto` }}>
        <Chat name="chat" />
      </div>
    </main>
  )
}
